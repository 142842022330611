import React, { Component } from 'react';
import Login from '../login/Login'
import AppMenu from '../menu/AppMenu'
import * as ReactDOM from "react-dom";

export const Display = () => {
};

export const displayLogin = () => {
    return(ReactDOM.render(<Login />, document.getElementById('body')));
};

export const displayMenu = (PEPLogo, sd, a) => {
    let attr = {PEPLogo: PEPLogo, sd: sd, attr: a};
    return(ReactDOM.render(
        <React.Fragment>
            <AppMenu attr={attr}/>
        </React.Fragment>, document.getElementById('body')));
};

export default Display;
