import React, { Component } from 'react';
import {withStyles} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import * as system from "../system/system";
import MaterialTable from "material-table";
import Search from '@material-ui/icons/Search'
import ViewColumn from '@material-ui/icons/ViewColumn'
import SaveAlt from '@material-ui/icons/SaveAlt'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import ResetSearch from '@material-ui/icons/Clear'
import Check from '@material-ui/icons/Check'
import FilterList from '@material-ui/icons/FilterList'
import Edit from '@material-ui/icons/Edit'
import Remove from '@material-ui/icons/Remove'
import Button from "@material-ui/core/Button";


const styles = ({
    root: {
        backgroundColor: '#f0f0f0',
        height: '94vh'
    },
    frame: {
        marginTop: '1%',
        marginLeft: '2%',
        marginRight: '2%',
    },
    tableFrame: {
        marginTop: '8px',
        marginLeft: '2%',
        marginRight: '2%',
        border: '1px solid lightgray',
        height: '76vh'
    },
    button: {
        backgroundColor: '#0099cc',
        color: 'white',
        height: '44px',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#0088cc',
        },
    },
});

class UsersTable extends Component {
    constructor(props) {
        super(props);
        const {classes, attr} = props;
        this.state = {
            classes: classes,
            data: attr.data,
            create: false,
            details: {},
            open: false,
            menu: attr.menu,
        };
    }

    handleClickCreate = () => {
        this.userDetails(true, {}, true, system.strings.userDetailsCreate);
    };

    userDetails = (create, details, open, msg) => {
        this.state.menu.setModule(system.DISPLAY_USER_DETAILS, {
            data: details,
            title: msg,
            create: create,
            open: open,
            menu: this.state.menu,
        });
    };

    render() {
        let classes = this.state.classes;
        return(<div className={classes.root}>
            <Grid container spacing={0} >
                <Grid item xs={12} className={classes.frame}>
                    <table>
                        <tr>
                            <td>
                                <Button variant="contained"
                                        className={classes.button}
                                        fullWidth
                                        onClick={this.handleClickCreate}>
                                    {system.strings.usersPasswordCreate}
                                </Button>
                            </td>
                        </tr>
                    </table>
                </Grid>
                <Grid item xs={12} className={classes.tableFrame}>
                    <MaterialTable
                        icons={{
                            Check: () => <Check />,
                            Export: () => <SaveAlt />,
                            Filter: () => <FilterList /> ,
                            FirstPage: () => <FirstPage /> ,
                            LastPage: () => <LastPage /> ,
                            NextPage: () => <ChevronRight /> ,
                            PreviousPage: () => <ChevronLeft /> ,
                            Search: () => <Search /> ,
                            ThirdStateCheck: () => <Remove /> ,
                            ViewColumn: () => <ViewColumn /> ,
                            DetailPanel: () => <ChevronRight /> ,
                            ResetSearch: () => <ResetSearch />,
                            Edit: () => <Edit />,

                        }}
                        title={system.strings.usersTableTitle}
                        columns={[
                            { title: system.strings.usersTableColUsername, field: 'userName' },
                            { title: system.strings.usersTableColStatus, field: 'enabled' },
                            { title: system.strings.usersTableColRole, field: 'role' },
                            { title: system.strings.usersTableColEmailAddress, field: 'emailAddress' },
                            { title: system.strings.usersTableColLastEntry, field: 'lastLogin' },
                        ]}
                        data={this.state.data}
                        options = {{
                            paging: false,
                            maxBodyHeight: '76vh',
                            search: false
                        }}
                        localization={{
                            body: {
                                emptyDataSourceMessage: system.strings.t_emptyDataSourceMessage,
                            },
                            header: {
                                actions: system.strings.usersTableActionsLabel
                            },
                        }}
                        actions={[
                            {
                                hidden: true,
                                icon: Edit,
                                tooltip: system.strings.usersTableModifyUser,
                                onClick: (event, rowData) => this.userDetails(false, rowData, true, system.strings.usersTableModifyUser),
                            }
                        ]}
                    />
                </Grid>
            </Grid>
        </div>);
    }
}


export default withStyles(styles)(UsersTable);
