import React, { Component } from 'react';
import {withStyles} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import * as system from "../system/system";
import MaterialTable from "material-table";

import Search from '@material-ui/icons/Search'
import ViewColumn from '@material-ui/icons/ViewColumn'
import SaveAlt from '@material-ui/icons/SaveAlt'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import ResetSearch from '@material-ui/icons/Clear'
import Check from '@material-ui/icons/Check'
import FilterList from '@material-ui/icons/FilterList'
import Remove from '@material-ui/icons/Remove'


const styles = ({
    root: {
        backgroundColor: '#f0f0f0',
        height: 'auto'
    },
    tableFrame: {
        marginTop: '2%',
        marginLeft: '2%',
        marginRight: '2%',
        border: '1px solid lightgray',
    },
});

class HistoryTable extends Component {
    constructor(props) {
        super(props);
        const {classes, attr} = props;
        this.state = {
            classes: classes,
            data: attr.data,
        };
    }

    render() {
        let classes = this.state.classes;
        return(<div className={classes.root}>
            <Grid container spacing={0} >
                <Grid item xs={12} className={classes.tableFrame}>
                    <MaterialTable
                        icons={{
                            Check: () => <Check />,
                            Export: () => <SaveAlt />,
                            Filter: () => <FilterList /> ,
                            FirstPage: () => <FirstPage /> ,
                            LastPage: () => <LastPage /> ,
                            NextPage: () => <ChevronRight /> ,
                            PreviousPage: () => <ChevronLeft /> ,
                            Search: () => <Search /> ,
                            ThirdStateCheck: () => <Remove /> ,
                            ViewColumn: () => <ViewColumn /> ,
                            DetailPanel: () => <ChevronRight /> ,
                            ResetSearch: () => <ResetSearch />,

                        }}
                        title={system.strings.historyTableTitle}
                        columns={[
                            { title: system.strings.historyTableColDate, field: 'executedAt' },
                            { title: system.strings.historyTableColName, field: 'name' },
                            { title: system.strings.historyTableColLists, field: 'lists' },
                            { title: system.strings.historyTableColHitCounter, field: 'hitCount' },
                        ]}
                        options = {{
                            paging: false,
                            maxBodyHeight: '80%',
                            search: false
                        }}
                        localization={{
                            body: {
                                emptyDataSourceMessage: system.strings.t_emptyDataSourceMessage
                            },
                        }}
                        data={this.state.data}
                    />
                </Grid>
            </Grid>
        </div>);
    }
}


export default withStyles(styles)(HistoryTable);
