import React, { Component } from 'react';
import Button from '@material-ui/core/Button/index';
import TextField from '@material-ui/core/TextField/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import {withStyles} from "@material-ui/core";
import * as system from "../system/system";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormHelperText from "@material-ui/core/FormHelperText";
import {displayLogin, displayMenu} from "../display/Display";

const styles = ({
    container: {
        minWidth: 400
    },
    inputNormal: {
        backgroundColor: 'white',
    },
    inputError: {
        backgroundColor: 'white',
        border: '2px solid red',
    },
    inputWarning: {
        backgroundColor: 'yellow',
    },
    errorInline: {
        display: 'inline',
        color: 'red',
        fontWeight: 'bold',
    },
    errorNone: {
        display: 'none',
        color: 'red',
        fontWeight: 'bold',
    },
});

class ChangePassword extends Component {
    constructor(props) {
        super(props);
        const {classes, attr} = props;
        this.state = {
            classes: classes,
            open: true,
            menu: attr.menu,
            data: {
                oldPassword: '',
                newPassword: '',
                confirmPassword: '',
            },
            error: {
                oldPassword: '',
                newPassword: '',
                confirmPassword: '',
                common: ''
            },
            sd: attr.sd,
        };
    }

    handleSelect = (event) => {
        const { target } = event;
        this.setState(preState => ({
            ...preState,
            data: {
                ...preState.data,
                [target.name]: target.value
            }
        }));
        if (target.name === 'confirmPassword') {
            if (this.state.data.newPassword !== target.value) {
                this.setError('confirmPassword', system.WARNING);
                return;
            }
        }
        this.setError(target.name, '');
        this.setError('common', '');
    };

    checkData = () => {
        let ret = true;
        for (let entry of Object.entries(this.state.data)) {
            if (entry[1].length === 0) {
                this.setError(entry[0], system.EMPTY);
                ret = false;
            }
        }
        if ((this.state.data.newPassword.length > 0 && this.state.data.oldPassword.length > 0)
            && (this.state.data.newPassword === this.state.data.oldPassword)) {
            this.setError('newPassword', system.strings.userDetailsErrPasswordsEquals);
            this.setError('oldPassword', system.strings.userDetailsErrPasswordsEquals);
            this.setState({confirmPassword: '', newPassword: ''});
            ret = false;
        }
        if ((this.state.data.newPassword !== this.state.data.confirmPassword) ) {
            this.setError('confirmPassword', system.strings.userDetailsErrPasswordsNotEquals);
            this.setError('newPassword', system.EMPTY);
            this.setState({confirmPassword: '', newPassword: ''});
            ret = false;
        }
        return ret;
    };

    setError = (id, errMsg) => {
        this.setState(preState => ({
            ...preState,
            error: {
                ...preState.error,
                [id]: errMsg
            }
        }));
    };

    handleCancel = () => {
        this.setState({ open: false });
        this.state.menu.handleMenuSearch();
    };

    handleOk = () => {
        if (this.checkData()) {
            let url = system.API_URL + 'changePassword/' + this.state.sd.accountId;
            fetch(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                body: JSON.stringify({
                    username: this.state.sd.userName,
                    oldPassword: this.state.data.oldPassword,
                    newPassword: this.state.data.confirmPassword,
                }),
            }).then((response) => {
                if (response.ok) {
                    alert(system.strings.successfulPasswordChange);
                    this.logout();
                }
                else {
                    this.errorHandler(response);
                }
            }).catch(error => {
                console.log(error);
            });
        }
    };

    logout = () => {
        fetch(system.API_URL + 'logout', {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        }).then((response) => {
            if (response.ok) {
                displayLogin();
            }
            else {
                this.errorHandler(response);
            }
        }).catch(error => {
            console.log(error);
        });
    };

    errorHandler = (response) => {
        this.setError('newPassword', system.EMPTY);
        this.setError('oldPassword', system.EMPTY);
        if (response.status === 403 || response.status === 400) {
            this.setError('common', system.strings.errorBadPassword);
        }
        else if (response.status === 500) {
            this.setError('common', 'Internal error');
        }
        else {
            this.setError('common', 'Error status code:' + response.status);
        }
    };

    createFields = (fields) => {
        let arr = [];
        let classes = this.state.classes;
        for(let i = 0; i < fields.length; i++) {
            let id = fields[i].id;
            let label = fields[i].label;
            let autoFocus = i === 0;
            let inputStyle = classes.inputNormal;
            let error = this.state.error[id];
            let value = this.state.data[id];
            if (error.length > 0) {
                inputStyle = (error === system.WARNING)
                    ? classes.inputWarning : classes.inputError;
                if (error === system.EMPTY || error === system.WARNING) {
                    error = '';
                }
            }
            let errorStyle = (error.length === 0) ? classes.errorNone : classes.errorInline;

            arr.push(<TextField
                    className={inputStyle}
                    autoFocus={autoFocus}
                    margin="dense"
                    id={id}
                    name={id}
                    label={label}
                    type="password"
                    fullWidth
                    value={value}
                    onChange={this.handleSelect}
                    InputProps={{
                        startAdornment: <InputAdornment position="start"/>,
                    }}
                />
            );
            arr.push(<div><FormHelperText className={errorStyle}>{error}</FormHelperText></div>);
        }
        return (<DialogContent>{arr}</DialogContent>)
    };

    render() {
        let classes = this.state.classes;
        let title = system.strings.changePasswordTitle + ': ' + this.state.sd.userName;
        let commonError = this.state.error.common;
        let commonErrorStyle = (commonError.length > 0) ? classes.errorInline: classes.errorNone;
        return (
            <div>
                <Dialog
                    open={this.state.open}
                    aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">{title}</DialogTitle>
                    <TextField
                        className={commonErrorStyle}
                        id="error"
                        type={'text'}
                        value={commonError}
                        margin="dense"
                        fullWidth
                        InputProps={{
                            startAdornment: <InputAdornment position="start"/>,
                            readonly: true
                        }}/>
                    {this.createFields([
                        {id: 'oldPassword', label: system.strings.changePasswordOldPassword},
                        {id: 'newPassword', label: system.strings.changePasswordNewPassword},
                        {id: 'confirmPassword', label: system.strings.changePasswordConfirmPassword}])}
                    <DialogActions>
                        <Button onClick={this.handleCancel} color="primary">
                            {system.strings.cancel}
                        </Button>
                        <Button onClick={this.handleOk} color="primary">OK</Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default withStyles(styles)(ChangePassword);
