import React from 'react';
import Login from './login/Login'
import ReactDOM from "react-dom";
import * as system from "./system/system";
import {displayLogin} from "./display/Display";

const App = () => {
  return (ReactDOM.render(<Login />, document.getElementById('body')));
};

export function initWarnTimer() {
  const timer1 = setTimeout(() => {
    initTimer()
  }, 29 * 60 * 1000);
  return () => clearTimeout(timer1);
}

function initTimer() {
  const timer2 = setTimeout(() => {
    fetch(system.API_URL + 'logout', {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      }
    }).then((response) => {
      if (response.ok) {
        displayLogin()
      }
    }).catch(error => {
      console.log(error);
    });
  }, 60 * 1000);
  return () => clearTimeout(timer2);
}

export default App;