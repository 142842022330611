import React, {Component} from 'react';
import {Typography, withStyles} from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import SearchIcon from '@material-ui/icons/Search';
import Button from "@material-ui/core/Button";
import SearchOptions from '../search/SearchOptions'
import EntriesTable from '../table/EntriesTable'
import HistoryTable from '../table/HistoryTable'
import UsersTable from '../table/UsersTable'
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {displayLogin} from '../display/Display';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import MenuIcon from '@material-ui/icons/Menu';
import UserDetails from "../dialogs/UserDetails";
import * as system from "../system/system";
import ChangePassword from "../dialogs/ChangePassword";
import logo from "../logo.png"


const styles = ({
    root: {
        grow: 1,
        backgroundColor: '#0f0f0f'
    },
    appLogo: {
        width: 16,
        height: 16,
        margin: '10px 30px 10px 12px',
    },
    rightLogos: {
        width: 16,
        height: 16,
        textAlign: 'right',
    },
    searchButton: {
        color: 'lightgray',
        marginLeft: 100,
        marginTop: 2,
        textTransform: 'none',
        '&:hover': {
            color: 'white',
        },
    },
    pointerCursor: {
        cursor: 'pointer'
    },
    displayErrorMessage: {
        marginLeft: 50,
        backgroundColor: 'lightgray',
        color: 'red',
        '&:hover': {
            backgroundColor: 'lightgray',
            color: 'red',
        },
        display: 'inline',
        fontWeight: 'bold',
    },
    displayWarningMessage: {
        marginLeft: 50,
        color: 'yellow',
        '&:hover': {
            color: 'yellow',
        },
        display: 'inline',
        fontWeight: 'bold',
    },
    hideMessage: {
        display: 'none',
    },
    sixLogoHide: {
        visibility: 'hidden',
        height: 32,
        marginRight: 30,
        verticalAlign: 'middle',
        marginBottom: 0,
    },
    sixLogoShow: {
        textAlign: 'right',
        height: 32,
        marginRight: 30,
        verticalAlign: 'middle',
        visibility: 'visible',
        marginBottom: 0,
    },
    moreButton: {
        color: 'lightgray',
        cursor: 'pointer',
        verticalAlign: 'middle',
        '&:hover': {
            color: 'white',
        },
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20,
    },
    toolbarButtons: {
        marginLeft: 'auto',
    },
});

const DISPLAY_SEARCH = '';
const DISPLAY_ENTRIES_TABLE = 'entriesTable';
const DISPLAY_HISTORY_TABLE = 'historyTable';
const DISPLAY_USERS_TABLE = 'usersTable';

class AppMenu extends Component {
    constructor(props) {
        super(props);
        const {classes, attr} = props;
        this.state = {
            classes: classes,
            PEPLogo: attr.PEPLogo,
            display: DISPLAY_SEARCH,
            searchOptions: null,
            entriesTableAttr: {},
            anchorEl: null,
            historyData: null,
            usersData: null,
            changePassword: null,
            message: '',
            messageType: '',
            sd: attr.sd,
            login: attr.login,
            attr: attr.attr,
        };
        if (attr.attr && attr.display) {
            this.setState({display: attr.display})
        }
    }

    emptyFunc = () => {
    };

    displayMessage = (message, type) => {
        this.setState({message: message, messageType: type});
        let timerId = setInterval(() => this.emptyFunc(), 0);
        setTimeout(() => {
            clearInterval(timerId);
            this.setState({message: ''});
        }, 6000);
    };

    setSearchOptions = (searchOptions) => {
        this.setState({searchOptions: searchOptions});
    };

    displayEntriesTable = (entriesTableAttr, isPep) => {
        this.setState({
            display: DISPLAY_ENTRIES_TABLE,
            entriesTableAttr: entriesTableAttr,
            PEPLogo: isPep
        });
        this.setState({state: this.state});
    };

    displayHistoryTable = () => {
        fetch(system.API_URL + 'histories/' + this.state.sd.accountId, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        }).then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                throw response;
            }

        }).then(json => {
            this.setState({
                historyData: json,
                display: DISPLAY_HISTORY_TABLE
            });
        }).catch(error => {
            console.log(error);
        });

        this.setState({state: this.state});
    };

    displayUsersTable = () => {
        fetch(system.API_URL + 'accounts', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        }).then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                throw response;
            }

        }).then(json => {
            this.setState({
                usersData: json,
                display: DISPLAY_USERS_TABLE
            });
        }).catch(error => {
            console.log(error);
        });

        this.setState({state: this.state});
    };

    handleClick = event => {
        this.setState({anchorEl: event.currentTarget});
    };

    handleClose = event => {
        this.setState({anchorEl: null});
    };

    handleMenuSearch = () => {
        this.setState({
            display: DISPLAY_SEARCH,
            PEPLogo: false
        });
        this.setState({state: this.state});
        this.state.searchOptions.setBaseState();
    };

    handleMenu = (option) => {
        this.setState({anchorEl: null});
        switch (option) {
            case system.strings.menuHistory:
                this.displayHistoryTable();
                break;
            case system.strings.menuUsers:
                this.displayUsersTable();
                break;
            case system.strings.menuChangePassword:
                this.setState({display: system.DISPLAY_CHANGE_PASSWORD, attr: {menu: this, sd: this.state.sd}});
                break;
            case system.strings.menuLanguage:
                this.setLanguage();
                break;
            case system.strings.menuExit:
                this.logout();
                break;
        }
    };

    logout = () => {
        fetch(system.API_URL + 'logout', {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        }).then((response) => {
            if (response.ok) {
                displayLogin();
            }
        }).catch(error => {
            console.log(error);
        });
    };

    setLanguage = () => {
        if (system.strings.menuLanguage === 'Nyelv -> EN') {
            system.strings.setLanguage('en');
        } else {
            system.strings.setLanguage('hu');
        }
        this.setState({open: false});
        this.setState({});
    };

    isAdmin = () => {
        if (this.state.sd) {
            for (let i = 0; i < this.state.sd.roles.length; i++) {
                if (this.state.sd.roles[i] === 'ROLE_ADMIN') {
                    return true;
                }
            }
        }
        return false;
    };

    renderMain() {
        let classes = this.state.classes;
        const {PEPLogo, anchorEl} = this.state;
        const open = Boolean(anchorEl);
        let displayStyle = classes.hideMessage;
        if (this.state.message.length > 0) {
            displayStyle = this.state.messageType === system.WARNING
                ? classes.displayWarningMessage : classes.displayErrorMessage;
        }

        const options = [];

        options.push(system.strings.menuEnteringAs + this.state.sd.userName);
        options.push(system.strings.menuHistory);
        if (this.isAdmin()) {
            options.push(system.strings.menuUsers);
        }
        options.push(system.strings.menuChangePassword);
        options.push(system.strings.menuLanguage);
        options.push(system.strings.menuExit);

        return (
            <div className={classes.root}>
                <AppBar position="static">
                    <Toolbar className={classes.root}>
                        <Avatar alt="AML logo" src={logo} className={classes.logo}/>
                        <Button variant="text"
                                className={classes.searchButton}
                                onClick={this.handleMenuSearch}>
                            {system.strings.menuSearch}
                            <SearchIcon/>
                        </Button>
                        <Button variant="text"
                                className={displayStyle}>
                            {this.state.message}
                        </Button>
                        <Typography className={classes.toolbarButtons}>
                            <MenuIcon className={classes.moreButton}
                                      aria-label="More"
                                      aria-owns={open ? 'long-menu' : undefined}
                                      aria-haspopup="true"
                                      onClick={this.handleClick}>
                                <MoreVertIcon className={classes.moreButton}/>
                            </MenuIcon>
                            <Menu
                                id="long-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={this.handleClose}
                                PaperProps={{
                                    style: {
                                        width: 200,
                                    },
                                }}>
                                {options.map(option => (
                                    <MenuItem key={option} onClick={() => this.handleMenu(option)}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Typography>
                    </Toolbar>
                </AppBar>
            </div>
        );
    }

    setModule = (display, attr) => {
        this.setState({display: display, attr: attr})
    };

    renderModule() {
        let display = this.state.display;
        switch (display) {
            case DISPLAY_SEARCH:
                return (<SearchOptions attr={{menu: this, sd: this.state.sd}}/>);
            case DISPLAY_ENTRIES_TABLE:
                return (<EntriesTable attr={this.state.entriesTableAttr}/>);
            case DISPLAY_HISTORY_TABLE:
                return (<HistoryTable attr={{data: this.state.historyData}}/>);
            case DISPLAY_USERS_TABLE:
                return (<UsersTable attr={{data: this.state.usersData, menu: this}}/>);
            case system.DISPLAY_USER_DETAILS:
                return (<UserDetails attr={this.state.attr}/>);
            case system.DISPLAY_CHANGE_PASSWORD:
                return (<ChangePassword attr={this.state.attr}/>);
        }
    }

    render() {
        return (<React.Fragment>
            {this.renderMain()}
            {this.renderModule()}
        </React.Fragment>);
    }
}

export default withStyles(styles)(AppMenu);

