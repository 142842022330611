import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import { unstable_Box as Box } from '@material-ui/core/Box';
import * as system  from "../system/system";
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import BrightnessIcon from '@material-ui/icons/Brightness1';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import classNames from 'classnames';
import InputAdornment from "@material-ui/core/InputAdornment";
import {displayLogin, displayMenu} from "../display/Display";
import logo from "../logo.png"

const itemText = ['Office of Foreign Assets Control (OFAC/SDN)',
    'Common Foreign and Security Policy (CFSP)',
    'EBRD Debarment list (EBRD)',
    'African Development Bank list of debarment entities (AFDB)',
    'Inter-American Development Bank sanctioned firms and individuals (IADB)',
    'World Bank Debarment list (WB)',
    'Asian Development Bank Sanction List (ADB)',
    'Denied Person List (DPL)',
    'Consolidated list of targets (MHT)'];

const styles = ({
    root: {
        flexGrow: 1,
        backgroundColor: '#f0f0f0',
        height: '100vh'
    },
    container: {
        marginTop: '1%',
        marginLeft: '15%',
        marginRight: '15%',
        backgroundColor: '#f0f0f0',
    },
    title: {
        marginTop: '1%',
        marginLeft: '15%',
        marginRight: '15%',
        color: '#0099cc',
        textAlign: 'center',
        fontSize: '40px'
    },
    body: {
        borderRadius: '8px',
        padding: '8px',
        textAlign: 'center',
        backgroundColor: '#424242',
        color: 'lightgray',
        textSize: '0.8em'
    },
    logo: {
        width: '80px',
        height: '80px',
        margin: '2%',
    },
    logoText1: {
        color: 'lightgray',
        fontSize: '40px',
        marginLeft: '14px',
        marginTop: 'auto',
        marginBottom: 'auto'
    },
    logoText2: {
        color: '#0099cc',
        fontSize: '40px',
        marginTop: 'auto',
        marginLeft: '-14px',
        marginBottom: 'auto',
        fontWeight: 'bold'
    },
    logoText: {
        color: 'lightgray',
        textAlign: 'left',
        marginLeft: '20px',
        padding: '-10px',
        textSize: '0.7em'
    },
    logoTextLast: {
        color: '#0099cc',
        textAlign: 'left',
        marginTop: '20px',
        marginBottom: '20px',
        marginLeft: '20px',
        textSize: '0.7em'
    },
    logoTextIcon: {
        textAlign: 'left',
        color: 'lightgray',
        marginRight: '10px'
    },
    rightGrid: {
        borderRadius: '8px',
        textAlign: 'left',
        backgroundColor: '#e0e0e0',
        width: '30%',
        height: '50%',
        margin: 'auto',
    },
    firstInput: {
        marginTop: 20,
    },
    lastInput: {
        marginBottom: 20,
    },
    inputEditor: {
        marginLeft: 20,
        marginRight: 20,
        height: 44,
    },
    inputNormal: {
        backgroundColor: 'white',
    },
    inputError: {
        backgroundColor: 'white',
        border: '2px solid red',
    },
    button: {
        backgroundColor: '#0099cc',
        color: 'white',
        height: '44px',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#0088cc',
        },
    },
    close: {
        padding: 8,
    },
    errorInline: {
        display: 'inline',
        color: 'red',
        fontWeight: 'bold',
    },
    errorNone: {
        display: 'none',
        color: 'red',
        fontWeight: 'bold',
    },
});

class Login extends Component {
    constructor(props) {
        super(props);
        const {classes} = props;
        this.state = {
            classes: classes,
            showPassword: false,
            password: '',
            userName: '',
            errorUserName: '',
            errorPassword: '',
        };
    }

    handleUsernameChange = prop => event => {
        this.setState({ [prop]: event.target.value, errorUserName: '', errorPassword: ''});
    };

    handlePasswordChange = prop => event => {
        this.setState({ [prop]: event.target.value,  errorPassword: '', errorUserName: ''});
    };

    handleClickLogin = () => {
        let err = false;
        if (this.state.userName.length === 0) {
            this.setState({errorUserName: system.EMPTY});
            err = true;
        }
        if (this.state.password.length === 0) {
            this.setState({errorPassword: system.EMPTY});
            err = true;
        }
        if (!err) {
            this.login();
        }
    };

    login = () => {
        fetch(system.API_URL + 'login', {
            method: 'POST',
            body: JSON.stringify({
                        username: this.state.userName,
                        password: this.state.password
            }),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        }).then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                this.errorHandler(response);
            }
        }).then(json => {
            console.log(json);
            if (json) {
                //initWarnTimer();
                displayMenu(false, json, this);
            }
        }).catch(error => {
            console.log(error);
        });
    };

    errorHandler = (response) => {
        if (response.status === 400) {
            this.setState({errorUserName: system.strings.errorBadUsername, userName: '', password: ''});
        }
        else if (response.status === 401) {
            this.setState({errorUserName: system.strings.unauthorizedUser, userName: '', password: ''});
        }
        else if (response.status === 403) {
            this.setState({errorPassword: system.strings.errorBadPassword, userName: '', password: ''});
        }
        else if (response.status === 409) {
            if (window.confirm(system.strings.activeSession)) {
                fetch(system.API_URL + 'logout', {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                    }
                }).then((response) => {
                    if (response.ok) {
                        this.setState({userName: '', password: ''});
                    }
                }).catch(error => {
                    this.setState({errorPassword: system.strings.errorBadPassword, userName: '', password: ''});
                });
            }
        }
        else if (response.status === 500) {
            this.setState({errorPassword: system.strings.conflictUser, userName: '', password: ''});
        }
        else {
            this.setState({errorPassword: response.status, userName: '', password: ''});
        }
    };

    render() {
        let classes = this.state.classes;
        let userNameStyle = this.state.errorUserName.length > 0 ? classes.inputError : classes.inputNormal;
        let passwordStyle = this.state.errorPassword.length > 0 ? classes.inputError : classes.inputNormal;
        let error = this.state.errorUserName.length > 0 ? this.state.errorUserName : this.state.errorPassword;
        if (error === system.EMPTY) {
            error = '';
        }
        let errorStyle = error.length > 0 ? classes.errorInline : classes.errorNone;
        return(<div className={classes.root}>
            <Grid container spacing={2}>
                <Grid item xs={12} className={classNames(classes.container, classes.title)}>
                    <Box>{system.strings.loginTitle}</Box>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} className={classNames(classes.container, classes.body)}>
                    <Grid container spacing={2}>
                        <Grid item xs={8}>
                            <Grid container spacing={2}>
                                <Grid item xs={1} className={classes.logo}>
                                    <Avatar alt="AML logo" src={logo} className={classes.logo} />
                                </Grid>
                                <Grid item xs={2} className={classes.logoText1}>Web</Grid>
                                <Grid item xs={1} className={classes.logoText2}>AML</Grid>
                            </Grid>
                            <Grid container spacing={4}>
                                <Grid item xs={12} className={classes.logoText}>
                                    {system.strings.loginText1}
                                </Grid>
                            </Grid>
                            <Grid container spacing={0}>
                                <Grid item xs={12} className={classes.logoText}>
                                    <List dense>
                                        {itemText.map((itemText) => {
                                            return(<ListItem dense>
                                                <ListItemIcon dense className={this.state.classes.logoTextIcon}>
                                                <BrightnessIcon fontSize="small"/>
                                                </ListItemIcon>{itemText}
                                            </ListItem>)})}
                                    </List>
                                </Grid>
                            </Grid>
                            <Grid container spacing={4}>
                                <Grid item xs={12} className={classes.logoText}>
                                    {system.strings.loginText2}
                                </Grid>
                            </Grid>
                            <Grid container spacing={4}>
                                <Grid item xs={12} className={classes.logoTextLast}>
                                    {system.strings.loginText3}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item sx={4} className={classes.rightGrid}>
                            <Grid container spacing={4}>
                                <Grid item xs={12} className={classNames(classes.inputEditor,
                                    classes.firstInput,
                                    errorStyle)}>
                                    <TextField
                                        className={errorStyle}
                                        fullWidth
                                        id="error"
                                        type={'text'}
                                        value={error}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start"/>,
                                            readonly: true
                                        }}/>
                                </Grid>
                            </Grid>
                            <Grid container spacing={4}>
                                <Grid item xs={12} className={classNames(classes.inputEditor, classes.firstInput)}>
                                        <TextField
                                            className={userNameStyle}
                                            fullWidth
                                            id="userName"
                                            autoFocus
                                            type={'text'}
                                            value={this.state.userName}
                                            label={system.strings.loginUserName}
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start"/>,
                                            }}
                                            onChange={this.handleUsernameChange('userName')}/>
                                </Grid>
                            </Grid>
                            <Grid container spacing={4}>
                                <Grid item xs={12} className={classNames(classes.inputEditor, classes.lastInput)}>
                                    <TextField
                                        className={passwordStyle}
                                        fullWidth
                                        margin="normal"
                                        id="password"
                                        type={'password'}
                                        value={this.state.password}
                                        label={system.strings.loginPassword}
                                        onChange={this.handlePasswordChange('password')}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start"/>,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={4}>
                                <Grid item xs={12} className={classNames(classes.inputEditor, classes.firstInput, classes.lastInput)}>
                                        <Button variant="contained"
                                                type = "default"
                                                className={classes.button}
                                                fullWidth
                                                onClick={this.handleClickLogin}>
                                            {system.strings.loginLogin}
                                        </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>);
    };
}

export default withStyles(styles)(Login);
