import React, { Component } from 'react';
import {withStyles} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import * as system from "../system/system";
import FormLabel from '@material-ui/core/FormLabel';
import MaterialTable from "material-table";

import Search from '@material-ui/icons/Search'
import ViewColumn from '@material-ui/icons/ViewColumn'
import SaveAlt from '@material-ui/icons/SaveAlt'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import ResetSearch from '@material-ui/icons/Clear'
import Check from '@material-ui/icons/Check'
import FilterList from '@material-ui/icons/FilterList'
import Remove from '@material-ui/icons/Remove'


const styles = ({
    root: {
        backgroundColor: '#f0f0f0',
        height: '100s'
    },
    frame: {
        marginTop: '2%',
        marginLeft: '2%',
        marginRight: '2%',
    },
    textNormal: {
        color: 'black',
        fontWeigh: 'normal',
        marginRight: '40px'
    },
    textBold: {
        color: 'black',
        fontWeigh: 'bold',
        marginRight: '4px'
    },
    tableFrame: {
        marginTop: '8px',
        marginLeft: '2%',
        marginRight: '2%',
        border: '1px solid lightgray',
    },
    innerDiv: {
        marginTop: '2%',
        marginLeft: '5%',
        marginRight: '5%',
        marginBottom: '2%',
        padding: 8,
        backgroundColor: '#f0f0f0',
        border: '1px solid lightgray',
        borderRadius: 8
    },
    innerTable: {
        borderCollapse: 'collapse',
        width: '100%'
    },
    innerTableCol1: {
      marginRight: '10px',
      borderBottom: '1px solid lightgray',
      borderRight: '1px solid lightgray',
      padding: 8,
      width: '40%'
    },
    innerTableCol1_h: {
        marginRight: '10px',
        borderBottom: '1px solid lightgray',
        padding: 8,
        width: '40%'
    },
    innerTableCol1_1: {
        marginRight: '10px',
        borderBottom: '1px solid lightgray',
        borderRight: '1px solid lightgray',
        padding: "8px 8px 8px 22px",
        width: '40%'
    },
    innerTableCol1_1_h: {
        marginRight: '10px',
        borderBottom: '1px solid lightgray',
        padding: "8px 8px 8px 22px",
        width: '40%'
    },
    innerTableCol1_2: {
        marginRight: '10px',
        borderBottom: '1px solid lightgray',
        borderRight: '1px solid lightgray',
        padding: "8px 8px 8px 24px",
        width: '40%'
    },
    innerTableCol1_2_h: {
        marginRight: '10px',
        borderBottom: '1px solid lightgray',
        padding: "8px 8px 8px 24px",
        width: '40%'
    },
    innerTableCol2: {
        marginRight: '10px',
        borderBottom: '1px solid lightgray',
        padding: 8
    },
});

class EntriesTable extends Component {
    constructor(props) {
        super(props);
        const {classes, attr} = props;
        this.state = {
            classes: classes,
            data: attr.data,
            searchName: attr.name,
            date: '',
            hitCounter: attr.data.length,
        };
    }

    displayDetails = (json) => {
        let arr = [];
        this.parseObject(JSON.parse(json), 0, arr);
        let classes = this.state.classes;
        return (<div className={classes.innerDiv}>
            <table className={classes.innerTable}>
                {arr}
            </table></div>);
    };

    parseObject = (o, level, arr) => {
        let classes = this.state.classes;
        let style = classes.innerTableCol1;
        let style_h = classes.innerTableCol1_h;
        if (level === 1) {
            style = classes.innerTableCol1_1;
            style_h = classes.innerTableCol1_1_h;
        }
        else if (level === 2) {
            style = classes.innerTableCol1_2;
            style_h = classes.innerTableCol1_2_h;
        }
        if (Object.prototype.toString.call(o) !== '[object Array]') {
            for (let key of Object.keys(o)) {
                let val = o[key];
                if (typeof val === 'string') {
                    if (val !== '') {
                        arr.push(<tr>
                            <td className={style}>
                                {key + ':'}
                            </td>
                            <td className={classes.innerTableCol2}>
                                {val}
                            </td>
                        </tr>);
                    }
                }
                else {
                    if (val !== '') {
                        arr.push(<tr>
                            <td className={style_h}>
                                {key}
                            </td>
                            <td className={classes.innerTableCol2}>
                            </td>
                        </tr>);
                    }
                    this.parseObject(val, level+1, arr)
                }
            }
        }
        else  {
            for(let i = 0; i < o.length; i++) {
                this.parseObject(o[i], level+1, arr)
            }
        }
    }

    parseArray = (a, level, arr) => {
        for (let key of Object.keys(a)) {
            let val = a[key];
            if (Array.isArray(val)) {
                console.log("**** arr==",val,"== level==",level,"==");
                this.parseArray(val, level+1, arr)
            }
            else  if (Object.isPrototypeOf(val)) {
                console.log("**** obj==",val,"== level==",level,"==");
                this.parseObject(val, level+1, arr)
            }
        }
    }

    tableHeader = (labels) => {
        let arr = [];
        let classes = this.state.classes;
        for(let i = 0; i < labels.length; i++) {
            if (i % 2 === 0) {
                arr.push(<td><FormLabel component="legend" className={classes.textBold}>{labels[i] + ':'}</FormLabel></td>);
            }
            else {
                arr.push(<td><FormLabel component="legend" className={classes.textNormal}>{labels[i]}</FormLabel></td>);
            }
        }
        return(<table><tr>{arr}</tr></table>)
    };

    render() {
        let classes = this.state.classes;
        let d = new Date();
        let date = d.toLocaleDateString() + ' ' + d.toLocaleTimeString();
        return(<div className={classes.root}>
            <Grid container spacing={0} >
                <Grid item xs={12} className={classes.frame}>
                    {this.tableHeader([
                        system.strings.searchName,
                        '"' + this.state.searchName + '"',
                        system.strings.entriesTableDate,
                        date,
                        system.strings.entriesTableHitCounter,
                        this.state.hitCounter
                    ])}
                </Grid>
                <Grid item xs={12} className={classes.tableFrame}>
                    <MaterialTable
                        icons={{
                            Check: () => <Check />,
                            Export: () => <SaveAlt />,
                            Filter: () => <FilterList /> ,
                            FirstPage: () => <FirstPage /> ,
                            LastPage: () => <LastPage /> ,
                            NextPage: () => <ChevronRight /> ,
                            PreviousPage: () => <ChevronLeft /> ,
                            Search: () => <Search /> ,
                            ThirdStateCheck: () => <Remove /> ,
                            ViewColumn: () => <ViewColumn /> ,
                            DetailPanel: () => <ChevronRight /> ,
                            ResetSearch: () => <ResetSearch />,

                        }}
                    title={system.strings.entriesTableTitle}
                    columns={[
                        { title: system.strings.entriesTableColName, field: 'name' },
                        { title: system.strings.entriesTableColList, field: 'list' },
                        { title: system.strings.entriesTableColId, field: 'id' },
                        { title: system.strings.entriesTableColScore, field: 'score' },
                    ]}
                    data={this.state.data}
                    detailPanel={rowData => {
                        return (this.displayDetails(rowData.details))
                    }}
                        options = {{
                            paging: false,
                            maxBodyHeight: '80vh',
                            search: true
                        }}
                        localization={{
                            body: {
                                emptyDataSourceMessage: system.strings.t_emptyDataSourceMessage
                            },
                            toolbar: {
                                searchTooltip: system.strings.menuSearch
                            },
                        }}
                        onRowClick={(event, rowData, togglePanel) => togglePanel()}
                    />
                </Grid>
            </Grid>
        </div>);
    }
}


export default withStyles(styles)(EntriesTable);
