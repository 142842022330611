import React, { Component } from 'react';
import Button from '@material-ui/core/Button/index';
import TextField from '@material-ui/core/TextField/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import {withStyles} from "@material-ui/core";
import * as system from "../system/system";
import InputAdornment from "@material-ui/core/InputAdornment";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";


const styles = ({
    container: {
        zIndex: 1,
        minWidth: 400,
    },
    inputNormal: {
        backgroundColor: 'white',
    },
    inputError: {
        backgroundColor: 'white',
        border: '2px solid red',
    },
    inputWarning: {
        backgroundColor: 'yellow',
    },
    errorInline: {
        display: 'inline',
        color: 'red',
        fontWeight: 'bold',
    },
    errorNone: {
        display: 'none',
        color: 'red',
        fontWeight: 'bold',
    },
});

class UserDetails extends Component {
    constructor(props) {
        super(props);
        const {classes, attr} = props;
        this.state = {
            sd: attr.sd,
            classes: classes,
            userTable: attr.userTable,
            data: {
                enabled: (attr.create) ? 'ENABLED': attr.data.enabled,
                userName:  (attr.create) ? '': attr.data.userName,
                role: (attr.create) ? 'ROLE_USER': attr.data.role,
                emailAddress: (attr.create) ? '': attr.data.emailAddress,
                beginnerPassword: '',
                confirmPassword: '',
            },
            error: {
                enabled: '',
                userName: '',
                role: '',
                emailAddress: '',
                beginnerPassword: '',
                confirmPassword: '',
            },
            title: attr.title,
            create: attr.create,
            menu: attr.menu,
            open: attr.open,
        };
    }

    handleCancel = () => {
        this.setState({ open: false });
        this.state.menu.setModule('usersTable',{});
    };

    handleOk = () => {
        if (this.checkData()) {
            if (this.state.create) {
                this.createUser();
            }
            else {
                this.modifyUser();
            }
        }
    };

    createUser = () => {
        fetch(system.API_URL + 'createAccount', {
            method: 'POST',
            body: JSON.stringify(this.state.data),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        }).then((response) => {
            if (response.ok) {
                this.setState({ open: false });
                this.state.menu.displayMessage(system.strings.userDetailsCreateUserOk, system.WARNING);
                this.state.menu.displayUsersTable();
            } else {
                this.handleError(response);
            }
        }).catch(error => {
            console.log(error);
        });
    };

    modifyUser = () => {
        fetch(system.API_URL + 'modifyAccount', {
            method: 'PUT',
            body: JSON.stringify(this.state.data),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        }).then((response) => {
            if (response.ok) {
                this.setState({ open: false });
                this.state.menu.displayMessage(system.strings.userDetailsModifyUserOk, system.WARNING);
                this.state.menu.displayUsersTable();
            } else {
                this.handleError(response);
            }
        }).catch(error => {
            console.log(error);
        });
    };

    handleSelect = (event) => {
        const { target } = event;
        this.setState(preState => ({
            ...preState,
            data: {
                ...preState.data,
                [target.name]: target.value
            }
        }));
        if (target.name === 'confirmPassword') {
            if (this.state.data.beginnerPassword !== target.value) {
                this.setError('confirmPassword', system.WARNING);
                return;
            }
        }
        this.setError(target.name, '');
    };

    handleError = (response) => {
        switch(response.status) {
            case 400:
                this.setError('confirmPassword', system.strings.userDetailsErrBadPassword);
                break;
            case 408:
                this.setError('userName', system.strings.userDetailsErrUsernameExists);
                break;
        }
    };

    setError = (id, errMsg) => {
        this.setState(preState => ({
            ...preState,
            error: {
                ...preState.error,
                [id]: errMsg
            }
        }));
    };

    createFields = (fields) => {
        let arr = [];
        let classes = this.state.classes;
        for(let i = 0; i < fields.length; i++) {
            let id = fields[i].id;
            let label = fields[i].label;
            if (id === 'beginnerPassword') {
                label = (this.state.create)
                    ? system.strings.userDetailsBeginnerPassword : system.strings.userDetailsNewPassword;
            }
            let type = fields[i].type;
            let autoFocus = i === 0;
            let inputStyle = classes.inputNormal;
            let errorStyle = classes.errorNone;
            let error = this.state.error[id];
            let value = this.state.data[id];
            let disabled = (((id === 'userName') && (!this.state.create)));
            let items = fields[i].items;
            if (error.length > 0) {
                inputStyle = (error === system.WARNING)
                    ? classes.inputWarning : classes.inputError;
                if (error === system.EMPTY || error === system.WARNING) {
                    error = '';
                }
                else {
                    errorStyle = classes.errorInline;
                }
            }
            if (type === 'select') {
                let helperId = id + '-helper';
                arr.push(<InputLabel htmlFor={helperId}>{label}</InputLabel>);
                arr.push(
                    <Select
                        className={inputStyle}
                        autoFocus={autoFocus}
                        margin="dense"
                        value={value}
                        name={id}
                        fullWidth
                        onChange={this.handleSelect}
                        input={<Input name={id} id={helperId} />}>
                        {this.createMenuItems(items)}
                        </Select>
                );
                arr.push(<div><FormHelperText className={errorStyle}>{error}</FormHelperText></div>);
            }
            else {
                arr.push(<TextField
                        disabled={disabled}
                        className={inputStyle}
                        autoFocus={autoFocus}
                        margin="dense"
                        id={id}
                        name={id}
                        label={label}
                        type={type}
                        fullWidth
                        value={value}
                        onChange={this.handleSelect}
                        InputProps={{
                            startAdornment: <InputAdornment position="start"/>,
                        }}
                    />
                );
                arr.push(<div><FormHelperText className={errorStyle}>{error}</FormHelperText></div>);
            }
        }
        return (<DialogContent>{arr}</DialogContent>)
    };

    createMenuItems = (items) => {
        let arr = [];
        for(let i = 0; i < items.length; i++) {
            let item = items[i];
            arr.push(<MenuItem value={item}>{item}</MenuItem>);
        }
        return arr;
    };

    checkData = () => {
        let ret = true;
        for (let entry of Object.entries(this.state.data)) {
            let id = entry[0];
            if (id !== 'emailAddress' && this.state.create) {
                if (entry[1].length === 0) {
                    this.setError(entry[0], system.EMPTY);
                    ret = false;
                }
            }
        }
        if (this.state.data.beginnerPassword !== this.state.data.confirmPassword) {
            this.setError('confirmPassword', system.strings.userDetailsErrPasswordsNotEquals);
            this.setError('beginnerPassword', system.EMPTY);
            this.setState({confirmPassword: '', beginnerPassword: ''});
            ret = false;
        }
        return ret;
    };

    render() {
        let classes = this.state.classes;
        return (
            <Dialog
                className={classes.container}
                open={this.state.open}
                aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{this.state.title}</DialogTitle>
                {this.createFields([
                    {id: 'userName', label: system.strings.userDetailsUserName, type: 'text'},
                    {id: 'enabled', label: system.strings.userDetailsStatus, type: 'select',
                        items:['ENABLED', 'DISABLED']},
                    {id: 'role', label: system.strings.userDetailsRole, type: 'select',
                        items:['ROLE_USER', 'ROLE_PEP_USER', 'ROLE_ADMIN']},
                    {id: 'emailAddress', label: system.strings.userDetailsUserEmail, type: 'email'},
                    {id: 'beginnerPassword', label: system.strings.userDetailsBeginnerPassword, type: 'password'},
                    {id: 'confirmPassword', label: system.strings.userDetailsConfirmPassword, type: 'password'}])}
                <DialogActions>
                    <Button onClick={this.handleCancel} color="primary">
                        {system.strings.cancel}
                    </Button>
                    <Button onClick={this.handleOk} color="primary">
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withStyles(styles)(UserDetails);
