import React, {Component} from 'react';
import {LinearProgress, withStyles} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import * as system from "../system/system";
import TextField from "@material-ui/core/TextField";
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from "@material-ui/core/Button";
import InputAdornment from "@material-ui/core/InputAdornment";

const styles = ({
    root: {
        height: '100%'
    },
    frame: {
        backgroundColor: '#f0f0f0',
        marginTop: '2%',
        marginLeft: '2%',
        marginRight: '2%',
        border: '1px solid lightgray',
    },
    nameFrame: {
        marginTop: '4%',
        marginLeft: '20%',
        marginRight: '20%',
        color: 'white',
    },
    name: {
        backgroundColor: 'white',
    },
    nameError: {
        backgroundColor: 'white',
        border: '2px solid red',
    },
    listsFrame1: {
        marginTop: '3%',
        marginLeft: '20%',
        marginRight: '20%',
    },
    listsFrame2: {
        marginLeft: '20%',
        marginRight: '20%',
    },
    listsFrame3: {
        marginRight: 40,
    },
    executionButton: {
        backgroundColor: '#0099cc',
        color: 'white',
        height: '44px',
        textTransform: 'none',
        textAlign: 'center',
        marginBottom: '4%',
        padding : 8,
        '&:hover': {
            backgroundColor: '#0088cc',
        },
    },
    listButton: {
        backgroundColor: '#0099cc',
        color: 'white',
        height: '44px',
        textTransform: 'none',
        textAlign: 'center',
        marginBottom: '4%',
        marginLeft: '40px',
        padding : 8,
        '&:hover': {
            backgroundColor: '#0088cc',
        },
    },
    close: {
        padding: 10,
    },
    listNormal: {
        color: 'black',
    },
    listError: {
        color: 'red'
    },
});

class SearchOptions extends Component {
    constructor(props) {
        super(props);
        const { classes, attr} = props;
        const {sd} = attr;
        this.state = {
            sd: sd,
            classes: classes,
            name: '',
            menu: attr.menu,
            nameError: false,
            listError: false,
            result: [],
            ALL: true,
            SDN:true,
            CFSP: true,
            BRD: true,
            AFDB: true,
            IADB: true,
            WB: true,
            ADB: true,
            DPL: true,
            HMT: true,
            UNL: true,
            EBRD: true,
            PEP: (sd.roles[0] !== 'ROLE_USER'),
            loading: false
        };
        this.state.menu.setSearchOptions(this);
    }

    isNotForbiddenKey = (key, list) => {
        if (list) {
            for(let i = 0; i < list.length; i++) {
                if (key === list[i]) {
                    return false;
                }
            }
        }
        return true;
    };

    setBaseState = () => {
      this.setState({name: ''});
        for (let key of Object.keys(this.state)) {
            if (this.isNotForbiddenKey(key, ['sd', 'classes', 'name', 'menu', 'nameError', 'listError', 'result'])) {
                this.setState({ [key]: true });
            }
        }
    };

    handleNameChange = prop => event => {
        this.setState({ [prop]: event.target.value,  nameError: false});
    };

    handleNameClick = prop => event => {
        this.setState({nameError: false, listError: false});
    };

    getLists = () => {
        let lists = [];
        for (let key of Object.keys(this.state)) {
            if (this.isNotForbiddenKey(key, ['sd', 'classes', 'name', 'ALL', 'menu', 'nameError', 'listError', 'result'])) {
                if (this.state[key]) {
                    lists.push(key);
                }
            }
        }
        return lists.join(",")
    };

    handleExecution = () => {
        let emptyName = this.state.name.length === 0;
        if (emptyName) {
            this.setState({nameError: true});
        }
        let lists = this.getLists();
        let emptyList = lists.length === 0;
        if (emptyList) {
            this.setState({listError: true});
        }
        if (!emptyName && !emptyList) {
            let url = this.state.name;
            let aId = this.state.sd.accountId;
            let query = system.API_URL +'entries/' + aId +  '?name=' + url + '&lists=' + lists;
            this.setState({loading: true})
            fetch(query, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                }
            }).then((response) => {
                this.setState({loading: false})
                if (response.ok) {
                    return response.json();
                } else {
                    throw response;
                }

            }).then(json => {
                this.state.menu.displayEntriesTable({
                    data: json,
                    name: this.state.name,
                }, this.isPepSelected());
            }).catch(error => {
                console.log(error);
            });
        }
    };

    getList = () => {
        let lists = this.getLists();
        return system.API_URL + 'list?lists=' + lists;
    };

    handleChange = name => event => {
        this.setState({ [name]: event.target.checked, listError: false, nameError: false });
    };

    handleAllChange = name => event => {
        this.setState({ [name]: event.target.checked, listError: false, nameError: false});
        for (let key of Object.keys(this.state)) {
            if (this.isNotForbiddenKey(key, ['sd', 'classes', 'name', 'ALL', 'menu', 'nameError'])) {
                this.setState({ [key]: event.target.checked });
            }
        }
    };

    isPepSelected = () => {
        for (let key of Object.keys(this.state)) {
            if (this.isNotForbiddenKey(key, ['sd', 'classes', 'name', 'ALL', 'menu', 'nameError'])) {
                if (key === 'PEP' && this.state[key]) {
                    return true;
                }
            }
        }
        return false;
    };

    createCheckboxGroup = (lists) => {
        let classes = this.state.classes;
        let arr = [];
        for(let i = 0; i < lists.length; i++) {
            arr.push(this.createCheckbox(lists[i]));
        }
        return (<FormControl component="fieldset" className={classes.listsFrame3}>
            <FormGroup>{arr}</FormGroup></FormControl>);
    };

    createCheckbox = (id) => {
     let classes = this.state.classes;
     let listStyle = this.state.listError ? classes.listError : classes.listNormal;
     return(<FormControlLabel
              control={
                  <Checkbox
                      checked={this.state[id]}
                      onChange={this.handleChange(id)}
                      value={id}
                      className={listStyle}/>
              }
              label={id}/>);
    };

    render() {
        let role = this.state.sd.roles[0];
        let classes = this.state.classes;
        let nameStyle = this.state.nameError ? classes.nameError : classes.name;
        let listStyle = this.state.listError ? classes.listError : classes.listNormal;
        let list1 = ['SDN','IADB','HMT'];
        let list2= ['CFSP','WB','UNL'];
        let list3 = ['EBRD','ADB'];
        if (role === 'ROLE_PEP_USER' || role === 'ROLE_ADMIN') {
            list3.push('PEP');
        }
        return(
            <div className={classes.root}>
                <Grid container spacing={0} >
                    <Grid item xs={12} className={classes.frame}>
                        <Grid item xs={12} className={classes.nameFrame}>
                            { this.state.loading ? <LinearProgress color="secondary" /> : null }
                            <TextField className={nameStyle}
                                margin="normal"
                                id="name"
                                autoFocus
                                fullWidth
                                value={this.state.name}
                                label={system.strings.searchName}
                                onChange={this.handleNameChange('name')}
                                onClick={this.handleNameClick('name')}
                                       InputProps={{
                                           startAdornment: <InputAdornment position="start"/>,
                                       }}
                            />
                        </Grid>
                        <Grid item xs={12} className={classes.listsFrame1}>
                            <FormControl component="fieldset" className={classes.listsFrame3}>
                                <FormLabel component="legend">{system.strings.searchLists}</FormLabel>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={this.state['ALL']}
                                                onChange={this.handleAllChange('ALL')}
                                                value="ALL"
                                                className={listStyle}/>
                                        }
                                        label={system.strings.searchAll}/>
                                </FormGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} className={classes.listsFrame2}>
                            {this.createCheckboxGroup(list1)}
                            {this.createCheckboxGroup(list2)}
                            {this.createCheckboxGroup(list3)}
                            {this.createCheckboxGroup(['AFDB','DPL'])}
                        </Grid>
                        <Grid item xs={12} className={classes.listsFrame1}>
                            <Button className={classes.executionButton}
                                    onClick={this.handleExecution}>{system.strings.searchExecution}
                            </Button>
                            {role === 'ROLE_ADMIN' &&
                                <Button className={classes.listButton}
                                    href={this.getList()}>{system.strings.menuList}
                                </Button>
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default withStyles(styles)(SearchOptions);
