import LocalizedStrings from 'react-localization';

export const DISPLAY_USER_DETAILS = 'userDetailsDialog';
export const DISPLAY_CHANGE_PASSWORD = 'changePasswordDialog';

export const WARNING = 'warning';
export const ERROR = 'error';
export const EMPTY = 'empty';

export const API_URL = '/api/1.0.0/';

export const strings = new LocalizedStrings({
    hu: {
        cancel: "Mégse",
        loginTitle: "Nemzetközi tiltólisták naprakész adatbázisa",
        loginText1: "A WebAML egy webes szolgáltatás, amely lekérdezési lehetőséget biztosít az alábbi legjelentősebb pénzmosás- és terroristafinanszírozás ellenes listákban:",
        loginText2 : 'A WebAML kereső motorja segítségével a felhasználó nem csak teljes egyezésre kereshet, ' +
            'hanem az alkalmazott Fuzzy logikán alapuló keresés esetleges névelírás esetén is megtalálhatja a ' +
            'keresett entitást. Pénzmosás és terroristafinanszírozás ellenes tevékenység végzése minden  pénzintézet ' +
            'számára törvéni kötelezettség. A WebAML e követelmény teljesítéséhez kínál egy hatékony megoldást.',
        loginText3: 'További információért kérem keressen minket e-mailen, vagy telefonon.',
        loginUserName: 'Felhasználó név',
        loginPassword: 'Jelszó',
        loginLogin: 'Belépés',
        menuSearch: 'Keresés',
        menuMore: 'Továbbiak',
        menuHistory: 'Történet',
        menuUsers: 'Felhasználók',
        menuChangePassword: 'Jelszóváltoztatás',
        menuLanguage: 'Nyelv -> EN',
        menuExit: 'Kilépés',
        menuHelp: 'Segítség',
        menuList: 'Lista',
        menuEnteringAs: "Belépve mint: ",
        searchName: 'Keresett név',
        searchLists: 'Listák',
        searchAll: 'Összes',
        searchExecution: 'Végrehajtás',
        searchEmptyList: "A lista nem tartalmaz megjeleníthető adatokat!",
        entriesTableDate: "Keresés dátuma:",
        entriesTableHitCounter: "Találatok száma:",
        entriesTableTitle: "Keresés eredménye",
        entriesTableColName: "Név",
        entriesTableColList: "Lista",
        entriesTableColId: "Azonosító",
        entriesTableColScore: "Egyezés mértéke",
        historyTableColDate: "Dátum",
        historyTableColName: "Név",
        historyTableColLists: "Listák",
        historyTableColHitCounter: "Találatszám",
        historyTableTitle: "Történet",
        usersTableColUsername: "Felhasználói név",
        usersTableColStatus: "Státusz",
        usersTableColRole: "Szerep",
        usersTableColEmailAddress: "email cím",
        usersTableColLastEntry: "Utolsó belépés",
        usersTableModifyUser: "Felhasználó módosítása",
        usersTableActionsLabel: "Műveletek",
        usersTableTitle: "Felhasználók",
        usersPasswordCreate: "Létrehozás",
        usersPasswordModify: "Módosítás",
        changePasswordOldPassword: "Régi jelszó",
        changePasswordNewPassword: "Új jelszó",
        changePasswordConfirmPassword: "Jelszó megerősítés",
        changePasswordTitle: "Jelszó változtatás",
        userDetailsStatus: "Státusz",
        userDetailsUserName: "Felhasználói név",
        userDetailsUserEmail: "email cím",
        userDetailsProfile: "Profil",
        userDetailsRole: "Szerep",
        userDetailsMenu: "Menü",
        userDetailsBeginnerPassword: "Kezdő jelszó",
        userDetailsNewPassword: "Új jelszó",
        userDetailsConfirmPassword: "Jelszó megerősítés",
        userDetailsCreate: "Új felhasználó létrehozása      ",
        userDetailsModify: "Felhasználó adatainak módosítása",
        userDetailsErrPasswordsEquals: "A jelszavak egyformák",
        userDetailsErrPasswordsNotEquals: "A jelszavak nem egyeznek meg",
        userDetailsErrUsernameExists: "A felhasználónév már létezik",
        userDetailsErrBadPassword: "Rossz (nem megfelelő formátumú) jelszó",
        userDetailsCreateUserOk: "A felhasználó sikeresen létrehozva!",
        userDetailsModifyUserOk: "A felhasználó adatai sikeresen módosítva!",
        t_emptyDataSourceMessage: "A tábla nem tartalmaz megejeleníthető adatokat!",
        errorBadPassword: "Rossz  jelszó!",
        errorBadUsername: "Rossz  felhasználónév!",
        activeSession: "Az Ön előző munkamenete még aktív!\nHasználja a 'Kilépés' menüpontot a munka biztonságos befejezéséhez!\nLezárja az előző munkamenetet és belép újra?",
        endOfSession: "Az Ön munkamenete lejárt! A rendszer most kilépteti!",
        warnEndOfSession: "Az Ön munkamenete 1 perc múlva lejár!",
        unauthorizedUser: "Jogosulatlan felhasználó!",
        serverError: "Szerver hiba!",
        successfulPasswordChange: "Sikeres jelszó változtatás!",
    },
    en:{
        cancel: "Cancel",
        loginTitle:"An up-to-date database of international block lists",
        loginText1: "WebAML is a web service that provides querying opportunities in the following major anti-money laundering and terrorist financing lists:",
        loginText2 : 'With the WebAML search engine, the user can not only search for full match, but you can also find in the case of fuzzy logic based on the applied Fuzzy logic'+
        'searched entity. Money laundering and anti-terrorist financing activities for all financial institutions. It is an obligation for him to do so. WebAML offers an effective solution to meet this requirement.',
        loginText3: 'For more information, please contact us by email or phone.',
        loginUserName: 'Username',
        loginPassword: 'Password',
        loginLogin: 'Login',
        menuSearch: 'Search',
        menuMore: 'More',
        menuHistory: 'History',
        menuUsers: 'Users',
        menuChangePassword: 'Change password',
        menuLanguage: 'Language -> HU',
        menuExit: 'Exit',
        menuHelp: 'Help',
        menuList: 'List',
        menuEnteringAs: "Entering as: ",
        searchName: 'Searched name',
        searchLists: 'Lists',
        searchAll: 'All',
        searchExecution: 'Execution',
        searchEmptyList: "The list does not contain displayable data!",
        entriesTableDate: "Date of search:",
        entriesTableHitCounter: "Hits counter:",
        entriesTableTitle: "Results of search",
        entriesTableColName: "Name",
        entriesTableColList: "List",
        entriesTableColId: "Id",
        entriesTableColScore: "Score",
        historyTableColDate: "Date",
        historyTableColName: "Name",
        historyTableColLists: "Lists",
        historyTableColHitCounter: "Hit counter",
        historyTableTitle: "History",
        usersTableColUsername: "Username",
        usersTableColStatus: "Status",
        usersTableColRole: "Role",
        usersTableColEmailAddress: "email address",
        usersTableColLastEntry: "Last entry",
        usersTableTitle: "Users",
        usersTableModifyUser: "Modify user",
        usersTableActionsLabel: "Actions",
        usersPasswordCreate: "Create",
        usersPasswordModify: "Modify",
        changePasswordOldPassword: "Old password",
        changePasswordNewPassword: "New password",
        changePasswordConfirmPassword: "Confirm password",
        changePasswordTitle: "Change password",
        userDetailsStatus: "Status",
        userDetailsUserName: "Username",
        userDetailsUserEmail: "email address",
        userDetailsProfile: "Profile",
        userDetailsRole: "Role",
        userDetailsMenu: "Menu",
        userDetailsBeginnerPassword: "Beginner password",
        userDetailsConfirmPassword: "Confirm password",
        userDetailsNewPassword: "New password",
        userDetailsCreate: "Create password",
        userDetailsModify: "Modify data of user",
        userDetailsErrPasswordsEquals: "Passwords is equals",
        userDetailsErrPasswordsNotEquals: "Passwords do not match",
        userDetailsErrBadPassword: "Bad (invalid format) password",
        userDetailsCreateUserOk: "User successfully created",
        userDetailsModifyUserOk: "The data of user successfully modified",
        userDetailsErrUsernameExists: "The username is exists!",
        t_emptyDataSourceMessage: "The table does not contain data that can be delivered!",
        errorBadPassword: "Bad password!",
        errorBadUsername: "Bad username!",
        activeSession: "You cannot log in because you have not completed your previous session! Exit now?",
        endOfSession: "Your session has expired! Exit now!",
        warnEndOfSession: "Your session will expire in 1 minute!",
        unauthorizedUser: "Unauthorized user!",
        serverError: "Server error!",
        successfulPasswordChange: "successful password change!",
    },
});
